import * as React from "react"
import ReactDOM from "react-dom"
import { Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"
import { BsArrowRight, BsBell, BsChevronDown } from "react-icons/bs"
import { FiFacebook, FiInstagram, FiTwitter } from "react-icons/fi"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import Logo from "../images/logo.svg"
import LogoWhite from "../images/logo-white.svg"

import ReserveSpace from "./ReserveSpace"
import Login from "./Login"
import ResetPassword from "./ResetPassword"

import { useApolloClient, useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"

export default () => {
  const apClient = useApolloClient()
  const GET_SIGNUP = gql`
    {
      showSignUp @client
    }
  `
  const GET_LOGIN = gql`
    {
      showLogin @client
    }
  `
  const GET_RESET = gql`
    {
      showReset @client
    }
  `
  const GET_STATUS = gql`
    {
      isLoggedIn @client
    }
  `
  let location = useLocation()

  const { data } = useQuery(GET_SIGNUP)
  const { data: LoginValue } = useQuery(GET_LOGIN)
  const { data: ResetValue } = useQuery(GET_RESET)
  const { data: LoggedIn } = useQuery(GET_STATUS)

  const showSignUp = data ? data.showSignUp : {}
  const showLogin = LoginValue ? LoginValue.showLogin : {}
  const showReset = ResetValue ? ResetValue.showReset : {}

  // const [isOpen, toggle] = React.useState<boolean>(true);
  const [toggle, setToggle] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      SIDEBAR: false,
      EXPLORE_MENU: false,
      COMPANY_MENU: false,
      LOGIN: false,
      SIGNUP: false,
      PASSWORD: false,
      HAS_SCROLLED: false,
    }
  )

  const handleScroll = () => {
    setToggle({ HAS_SCROLLED: window.scrollY > 100 })
  }

  React.useEffect(() => {
    const handleNavigation = () => {
      if (location.pathname === "/") {
        handleScroll()
        window.addEventListener("scroll", handleScroll, { passive: true })
      } else {
        window.removeEventListener("scroll", handleScroll)
        setToggle({ HAS_SCROLLED: true })
      }
    }

    handleNavigation()

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [location.pathname])

  // @ts-ignore
  // React.useLayoutEffect(() => {
  // Get original body overflow
  // const originalStyle = window.getComputedStyle(document.body).overflow;

  // Prevent scrolling on mount
  // document.body.style.overflow = "hidden";

  // Re-enable scrolling when component unmounts
  //   return () => (document.body.style.overflow = originalStyle);
  // }, []);
  // const [ loading, updateloadingState ] = React.useState<boolean | undefined>(false);
  // const [ message, setMessage ] = React.useState<String | null>('');

  // const URL = 'https://vpark-202701.appspot.com/graphql';
  return (
    <React.Fragment>
      {/* Our response to covid 19 */}
      {/* <Link
        to="/covid-19"
        className="bg-black text-center lg:px-4 w-full fixed top-0 z-50"
      >
        <div
          className="p-2 w-full lg:w-2/3 items-center justify-center text-yellow-500 leading-none flex mx-auto"
          role="alert"
        >
          <BsBell color="white" />
          <span className="text-white uppercase px-2 py-1 text-xs font-black mr-3">
            Important
          </span>
          <span className="font-bold mr-2 text-left text-sm">
            Our response to COVID-19
          </span>
          <BsArrowRight size={24} />
        </div>
      </Link> */}

      {/* Main navabr with logo, Login and Menu button */}
      <nav
        className={`flex items-center justify-between ${
          toggle.HAS_SCROLLED ? "bg-white" : "bg-transparent"
        } px-6 lg:px-10 h-navbar font-bold fixed top-alert w-full border-t-6 border-yellow-500 z-50`}
      >
        <Link
          to="/"
          className="flex items-center flex-shrink-0 text-black mr-6"
        >
          {toggle.HAS_SCROLLED ? (
            <img src={Logo} alt="Ventures Park" className="h-logo" />
          ) : (
            <img src={LogoWhite} alt="Ventures Park" className="h-logo" />
          )}
        </Link>

        <div
          className={`flex lg:items-center lg:w-auto ${
            toggle.HAS_SCROLLED ? "text-gray-600" : "text-white"
          }`}
        >
          {/* {!LoggedIn.isLoggedIn ? (
            <button
              className="text-sm md:text-base flex items-center px-3 py-2 text-gray-600 hover:text-black"
              onClick={() => setToggle({ LOGIN: true })}
            >
              Login
            </button>
          ) : (
            <Link
              to="/profile"
              className="text-sm md:text-base flex items-center px-3 py-2 text-gray-600 hover:text-black"
            >
              Profile
            </Link>
          )} */}
          <Popover className="relative">
            <Popover.Button className="text-sm md:text-base items-center px-3 py-2 text-inherit hidden sm:flex hover:text-gray-300">
              Explore
              <ChevronDownIcon className="ui-open:rotate-180 ui-open:transform size-6" />
            </Popover.Button>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 mt-0 max-w-sm transform px-4 sm:px-0 lg:max-w-3xl">
                <div className="overflow-hidden shadow-lg ring-1 ring-black/5 min-w-max">
                  <div className="relative grid bg-white text-gray-600 p-2">
                    <Link
                      to="/coworking-spaces"
                      className="text-sm md:text-base items-center px-3 py-2  hidden sm:flex hover:text-gray-300"
                    >
                      Coworking Space
                    </Link>
                    <Link
                      to="/event-spaces"
                      className="text-sm md:text-base items-center px-3 py-2  hidden sm:flex hover:text-gray-300"
                    >
                      Event Spaces
                    </Link>
                    <Link
                      to="/book-a-tour"
                      className="text-sm md:text-base items-center px-3 py-2  hidden sm:flex hover:text-gray-300"
                    >
                      Book a Tour
                    </Link>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <Popover className="relative">
            <Popover.Button className="text-sm md:text-base items-center px-3 py-2 text-inherit hidden sm:flex hover:text-gray-300 focus-visible:outline-black">
              Company
              <ChevronDownIcon className="ui-open:rotate-180 ui-open:transform size-6" />
            </Popover.Button>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 mt-0 max-w-sm transform px-4 sm:px-0 lg:max-w-3xl">
                <div className="overflow-hidden shadow-lg ring-1 ring-black/5 min-w-max">
                  <div className="relative grid bg-white text-gray-600 p-2">
                    <Link
                      to="/about"
                      className="text-sm md:text-base items-center px-3 py-2  hidden sm:flex hover:text-gray-300"
                    >
                      About Us
                    </Link>
                    <Link
                      to="/programs"
                      className="text-sm md:text-base items-center px-3 py-2  hidden sm:flex hover:text-gray-300"
                    >
                      Programs &amp; Partnerships
                    </Link>
                    <Link
                      to="/careers"
                      className="text-sm md:text-base items-center px-3 py-2  hidden sm:flex hover:text-gray-300"
                    >
                      Careers
                    </Link>
                    <Link
                      to="/gallery"
                      className="text-sm md:text-base items-center px-3 py-2  hidden sm:flex hover:text-gray-300"
                    >
                      Gallery
                    </Link>
                    <Link
                      to="/contact-us"
                      className="text-sm md:text-base items-center px-3 py-2  hidden sm:flex hover:text-gray-300"
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <Popover className="relative">
            <Popover.Button className="text-sm md:text-base items-center px-3 py-2 text-inherit hidden sm:flex hover:text-gray-300">
              Community
              <ChevronDownIcon className="ui-open:rotate-180 ui-open:transform size-6" />
            </Popover.Button>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 mt-0 max-w-sm transform px-4 sm:px-0 lg:max-w-3xl">
                <div className="overflow-hidden shadow-lg ring-1 ring-black/5 min-w-max">
                  <div className="relative grid bg-white text-gray-600 p-2">
                    <Link
                      to="/resources"
                      className="text-sm md:text-base items-center px-3 py-2  hidden sm:flex hover:text-gray-300"
                    >
                      Resources
                    </Link>
                    <Link
                      to="/events"
                      className="text-sm md:text-base items-center px-3 py-2  hidden sm:flex hover:text-gray-300"
                    >
                      Events
                    </Link>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <Link
            to="/blog"
            className="text-sm md:text-base items-center px-3 py-2 text-inherit hidden sm:flex hover:text-gray-300"
          >
            Blog
          </Link>
          <button
            className={`text-sm md:text-base flex items-center px-3 py-2 sm:hidden`}
            onClick={() => setToggle({ SIDEBAR: true })}
          >
            <span className="mr-2">Menu</span>
            <svg className="fill-current h-6 w-6" viewBox="0 0 20 20">
              <title>Menu</title>
              <path d="M0 5h20v1H0V3zm0 4h16v1H0V9zm0 4h20v1H0v-2z" />
            </svg>
          </button>
        </div>
      </nav>

      {/* Toggle main sidebar */}
      <aside
        className={`border-t-6 border-yellow-500 w-full flex h-sidebar overflow-y-auto bg-white z-100 lg:w-sidebar fixed top-alert right-0 shadow-lg transform duration-300 origin-right ${
          toggle.SIDEBAR
            ? "translate-x-0 ease-out transition-medium"
            : "-translate-x-200 ease-in transition-medium"
        }`}
        role="navigation"
      >
        <div className="w-10/12 mx-auto flex flex-col py-5 relative z-100">
          <button
            className="flex items-center px-3 py-2 text-gray-600 hover:text-black ml-auto"
            onClick={() => setToggle({ SIDEBAR: false })}
          >
            <span className="text-sm align-baseline mr-2">CLOSE</span>
            <svg className="fill-current h-6 w-6" viewBox="0 0 20 20">
              <title>Menu</title>
              <rect
                x="3.70703"
                y="3"
                width="20"
                height="1"
                transform="rotate(45 3.70703 3)"
              />
              <rect
                x="3"
                y="17.1421"
                width="20"
                height="1"
                transform="rotate(-45 3 17.1421)"
              />
            </svg>
          </button>
          <div
            className="flex flex-col"
            onClick={() => setToggle({ SIDEBAR: false })}
          >
            <Link activeClassName="active" to="/" className="nav-link mb-4">
              Home
            </Link>
            {/* Explore Menu */}
            <button
              type="button"
              onClick={(evt) => {
                evt.stopPropagation()
                setToggle({ EXPLORE_MENU: !toggle.EXPLORE_MENU })
              }}
              className="px-4 py-2 inline-flex text-gray-600 hover:text-black mb-4 font-bold text-xl items-center"
            >
              Explore <BsChevronDown className="ml-3" />
            </button>
            <div
              className={`flex flex-col border-l-2 border-gray-200 ml-4 ${
                toggle.EXPLORE_MENU ? "h-auto" : "h-0 overflow-hidden"
              }`}
            >
              <Link
                activeClassName="active"
                to="/coworking-spaces"
                className="nav-link mb-4"
              >
                Coworking Space
              </Link>
              <Link
                activeClassName="active"
                to="/event-spaces"
                className="nav-link"
              >
                Event Spaces
              </Link>
            </div>
            {/* End Explore Menu */}
            {/* Company Menu */}
            <button
              type="button"
              onClick={(evt) => {
                evt.stopPropagation()
                setToggle({ COMPANY_MENU: !toggle.COMPANY_MENU })
              }}
              className="px-4 py-2 inline-flex text-gray-600 hover:text-black mb-4 font-bold text-xl items-center"
            >
              Company <BsChevronDown className="ml-3" />
            </button>
            <div
              className={`flex flex-col border-l-2 border-gray-200 ml-4 ${
                toggle.COMPANY_MENU ? "h-auto mb-4" : "h-0 overflow-hidden"
              }`}
            >
              <Link
                activeClassName="active"
                to="/about"
                className="nav-link mb-4"
              >
                About Us
              </Link>
              <Link
                activeClassName="active"
                to="/careers"
                className="nav-link mb-4"
              >
                Careers
              </Link>
              <Link
                activeClassName="active"
                to="/programs"
                className="nav-link mb-4"
              >
                Programs &amp; Partnerships
              </Link>
              <Link
                activeClassName="active"
                to="/gallery"
                className="nav-link mb-4"
              >
                Gallery
              </Link>
              <Link
                activeClassName="active"
                to="/contact-us"
                className="nav-link"
              >
                Contact Us
              </Link>
            </div>
            {/* End Company Menu */}
            <Link
              activeClassName="active"
              to="/book-a-tour"
              className="nav-link mb-4"
            >
              Book a Tour
            </Link>
            <Link
              activeClassName="active"
              to="/resources"
              className="nav-link mb-4"
            >
              Resources
            </Link>
            <Link activeClassName="active" to="/blog" className="nav-link mb-4">
              Blog
            </Link>
            <Link
              activeClassName="active"
              to="/virtual-park"
              className="nav-link mb-4"
            >
              Virtual Park
            </Link>
            {/* <p className="uppercase text-gray-400 text-sm font-extrabold px-4">
              New Here?
            </p>
            <button
              type="button"
              onClick={(evt) => {
                evt.stopPropagation()
                setToggle({ SIGNUP: true, SIDEBAR: false })
              }}
              className="pb-4 text-left px-4 text-gray-600 hover:text-black mb-4 font-bold text-xl"
            >
              Reserve a Space
            </button> */}
          </div>

          <p className="uppercase text-gray-400 text-sm font-extrabold px-4 mt-auto">
            Follow Us
          </p>
          <div className="flex flex-row flex-wrap px-3 pt-2">
            <a
              href="//instagram.com/theventurespark"
              className="text-3xl text-gray-50 hover:text-black mb-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiInstagram />
            </a>
            <a
              href="//twitter.com/theventurespark"
              className="text-3xl text-gray-50 hover:text-black mb-2 ml-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiTwitter />
            </a>
            <a
              href="//facebook.com/theventurespark"
              className="text-3xl text-gray-50 hover:text-black mb-2 ml-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiFacebook />
            </a>
          </div>
        </div>
      </aside>
      {/* {toggle.SIDEBAR || showLogin || showSignUp ? (
        ReactDOM.createPortal(
          <div
            className="h-sidebar bg-gray-100 bg-opacity-75 w-full fixed top-alert z-50"
            onClick={() => setToggle({ SIDEBAR: false })}
          />,
          document.body
        )
      ) : null} */}
      {toggle.SIDEBAR ||
        toggle.LOGIN ||
        (toggle.SIGNUP &&
          ReactDOM.createPortal(
            <div
              className="h-sidebar bg-gray-100 bg-opacity-75 w-full fixed top-alert z-50"
              onClick={() => setToggle({ SIDEBAR: false })}
            />,
            document.body
          ))}
    </React.Fragment>
  )
}
