import dayjs from "dayjs"
import React from "react"
import { Link } from "gatsby"
interface ICard {
  /**
   * Title
   */
  title: string
  /**
   * Description
   */
  description?: string
  /**
   * Link
   * - must be `react-router` Link props
   */
  link?: string
  tags?: any
  children?: React.ReactNode
}

export const Precautions: React.FC<Omit<ICard, "link">> = ({
  title,
  description,
  children,
}) => (
  <div className="w-full lg:w-1/3 overflow-hidden mb-6 px-4 lg:px-6 hover:border-black">
    {children}
    <div className="py-4">
      <div className="font-bold text-xl">{title}</div>
      <p className="text-gray-700 text-base font-medium">{description}</p>
    </div>
  </div>
)

export const Post: React.FC<ICard & { data: any }> = ({
  title,
  tags,
  link = "/",
  children,
  data,
}) => (
  <Link
    to={link}
    className="w-full lg:w-1/3 overflow-hidden mb-6 px-4 lg:px-6 text-black hover:text-gray-600"
  >
    {children}
    <div className="py-4">
      <div className="font-bold text-xl">{title}</div>
      <div className="">
        <p className="text-gray-600 text-sm tracking-tight font-semibold">
          {dayjs(data.published).format("MMM DD, YYYY")}
        </p>
      </div>
      <div className="flex flex-row items-start flex-wrap pt-2">
        {tags &&
          tags.length > 0 &&
          tags.map((item: any, index: any) => (
            <span
              key={index}
              className="text-sm mr-2 mb-2 block text-black border-b-2 border-yellow-500 bg-yellow-100"
            >
              {item}
            </span>
          ))}
      </div>
    </div>
  </Link>
)

export const FAQ: React.FC<Omit<ICard, "link" | "description" | "tags">> = ({
  title,
  children,
}) => (
  <details className="mb-4">
    <summary>
      <h4 className="font-extrabold tracking-tight text-xl mb-2 inline cursor-pointer">
        {title}
      </h4>
    </summary>
    {children}
  </details>
)

export const VirtualFeature: React.FC<Omit<ICard, "link">> = ({
  title,
  description,
  children,
}) => (
  <div className="w-full lg:w-1/2 overflow-hidden mb-6 px-6">
    <div className="flex flex-col lg:flex-row">
      <span className="mb-4 mr-4 flex-shrink-0 flex-grow-0 w-16">
        {children}
      </span>
      <div className="flex-auto">
        <h4 className="text-xl font-bold">{title}</h4>
        <p className="text-gray-700 text-base font-medium">{description}</p>
      </div>
    </div>
  </div>
)

/**
 *
 * @param title
 * Space type available
 *
 * @param description
 * Space description
 *
 * @param link
 * URL to space
 *
 */
export const Spaces: React.FC<ICard & { linkTitle?: string}> = ({
  title,
  description,
  link = "/",
  linkTitle = "Learn More",
  children,
}) => (
  <div className="w-full lg:w-1/3 overflow-hidden mb-6">
    <div className="lg:px-4">
      {children}
      <div className="py-4">
        <div className="font-semibold text-2xl mb-2">{title}</div>
        <p className="text-gray-600 text-base font-medium">{description}</p>
      </div>
      <div className="pb-4">
        <Link
          to={link}
          className="inline-block bg-white hover:bg-black hover:text-white text-black font-semibold py-2 px-4 border-black border-2"
        >
          {linkTitle}
          <svg
            className="inline transform translate-x-3 -mr-6"
            width="40px"
            fill="none"
            viewBox="0 0 44 9"
          >
            <path
              stroke="currentColor"
              strokeWidth="2"
              d="M1 4.5c2.333-4.667 4.667-4.667 7 0s4.667 4.667 7 0 4.667-4.667 7 0 4.667 4.667 7 0 4.667-4.667 7 0 4.667 4.667 7 0"
            />
          </svg>
        </Link>
      </div>
    </div>
  </div>
)

/**
 *
 * @param title
 * Card title
 *
 * @param description
 * Card description/subheading
 *
 * @param link
 * If card has a link,
 *
 */
export const Default: React.FC<ICard & {}> = ({
  title,
  description,
  link,
  children,
}) => (
  <div className={`w-full md:w-1/3 overflow-hidden`}>
    <div className="">
      {children}
      <div className="py-4 px-4">
        <div className="font-semibold text-xl mb-2">{title}</div>
        {description && (
          <p className="text-gray-600 text-base font-medium">{description}</p>
        )}
      {link && link !== null && (
        <div className="pb-4">
          <Link to={link} className="inline-block text-link">
            Read More
          </Link>
        </div>
      )}
      </div>
    </div>
  </div>
)

export default Spaces
