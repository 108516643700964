import React from 'react';

const Loader = () => (
	<svg aria-labelledby="loading-14-aria" role="img" viewBox="0 0 730 304">
		<title id="loading-14-aria">Loading...</title>
		<rect
			role="presentation"
			x="0"
			y="0"
			width="100%"
			height="100%"
			clipPath="url(#loading-14-diff)"
			style={{ fill: 'url(#loading-14-animated-diff' }}
		/>
		<defs role="presentation">
			<clipPath id="loading-14-diff">
				<rect x="583" y="33" rx="2" ry="2" width="123" height="33" />
				<rect x="66" y="49" rx="0" ry="0" width="165" height="11" />
				<rect x="66" y="97" rx="0" ry="0" width="202" height="41" />
				<rect x="284" y="97" rx="0" ry="0" width="202" height="41" />
				<rect x="506" y="96" rx="0" ry="0" width="202" height="41" />
				<rect x="66" y="155" rx="0" ry="0" width="642" height="4" />
				<rect x="66" y="175" rx="0" ry="0" width="642" height="4" />
				<rect x="65" y="195" rx="0" ry="0" width="642" height="4" />
				<rect x="66" y="215" rx="0" ry="0" width="642" height="4" />
				<rect x="66" y="236" rx="0" ry="0" width="642" height="4" />
				<rect x="65" y="256" rx="0" ry="0" width="642" height="4" />
				<rect x="315" y="274" rx="0" ry="0" width="31" height="28" />
				<rect x="356" y="274" rx="0" ry="0" width="31" height="28" />
				<rect x="396" y="274" rx="0" ry="0" width="31" height="28" />
			</clipPath>
			<linearGradient id="loading-14-animated-diff">
				<stop offset="0%" stopColor="#f5f6f7" stopOpacity="1">
					<animate
						attributeName="offset"
						values="-2; -2; 1"
						keyTimes="0; 0.25; 1"
						dur="1s"
						repeatCount="indefinite"
					/>
				</stop>
				<stop offset="50%" stopColor="#eee" stopOpacity="1">
					<animate
						attributeName="offset"
						values="-1; -1; 2"
						keyTimes="0; 0.25; 1"
						dur="1s"
						repeatCount="indefinite"
					/>
				</stop>
				<stop offset="100%" stopColor="#f5f6f7" stopOpacity="1">
					<animate
						attributeName="offset"
						values="0; 0; 3"
						keyTimes="0; 0.25; 1"
						dur="1s"
						repeatCount="indefinite"
					/>
				</stop>
			</linearGradient>
		</defs>
	</svg>
);

export default Loader;
