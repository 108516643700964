import gql from 'graphql-tag';

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!,
    showSignUp: Boolean!,
    showLogin: Boolean!  
    showReset: Boolean! 
  }
`;

export const resolvers = {};