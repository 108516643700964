import React from "react";
// import Empty from "../images/empty.placeholder.svg";

interface Props {
  clearError?: any;
  title?: string;
  onClick?: any;
}

const Error: React.FC<Props> = () => (
  <section className="gird grid-cols-1fr grid-rows-1">
    <div className="flex flex-column justify-center items-center text-center p-8">
      <p>Oops — something went wrong.</p>
      <p>Try refreshing this page again</p>
    </div>
  </section>
);

// export const NetworkError: React.FC<Props> = ({ title, onClick }) => (
//   <Flex
//     flexDir="column"
//     alignItems="center"
//     py="10"
//     textAlign="center"
//     minHeight="90vh"
//   >
//     <Text fontSize="xl" fontWeight="bold">
//       {title}
//     </Text>
//     <Text marginBottom="8" color="gray.400">
//       Unable to complete your request at the moment.
//     </Text>
//     <Button
//       variantColor="blue"
//       variant="outline"
//       borderRadius="8px"
//       size="lg"
//       onClick={onClick}
//     >
//       Try again
//     </Button>
//   </Flex>
// );

interface IErrorBoundaryProps {
  children?: JSX.Element;
}

interface IErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState, any> {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }
  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service    logErrorToMyService(error, errorInfo);
    console.log(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI      return <h1>Something went wrong.</h1>;    }
      return <Error />;
    }
    return this.props.children;
  }
}
