import * as React from "react"
import { FiFacebook, FiInstagram, FiLinkedin, FiTwitter } from "react-icons/fi"
import { Link } from "gatsby"

import axios from "axios"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { BsInfoCircle } from "react-icons/bs"
import * as Yup from "yup"

export default () => {
  const [loading, updateloadingState] = React.useState<boolean | undefined>(
    false
  )
  const [message, setMessage] = React.useState<String | null>("")

  const URL = "https://api.theventurespark.com/graphql"

  return (
    <React.Fragment>
      <hr className="w-full mt-24" />
      <footer className="container py-16 lg:px-12">
        <div className="flex flex-row flex-wrap px-6 pt-16 lg:px-0">
          <aside className="w-full lg:w-2/4 mx-auto pb-16 text-center">
            <h2 className="font-black text-3xl lg:text-4xl text-center tracking-tight leading-snug mb-2">
              Join our community
            </h2>
            <p className="text-xl mb-4">
              Become a part of our community. Get the latest news from our front
              desk sent to your inbox weekly.
            </p>

            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Email is invalid")
                  .required("Please enter email"),
              })}
              onSubmit={async (values) => {
                updateloadingState(true)

                let dataToBePassed = JSON.stringify({
                  query: `mutation { subscribe ( email: "${values.email}"){message} } `,
                })

                const headerConfig = {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                }

                try {
                  let response = await axios.post(URL, dataToBePassed, {
                    headers: headerConfig,
                  })

                  if (!response) setMessage("Oops something happened")

                  if (response.data.data.subscribe.message === "success") {
                    updateloadingState(false)
                    setMessage("Subscribed successfully")
                  }
                } catch (err) {
                  // @ts-ignore
                  setMessage(err.message)
                }
              }}
            >
              {({ errors, touched }) => (
                <Form className="w-full max-w-none">
                  <div className="flex flex-col lg:flex-row items- py-2">
                    <Field
                      placeholder="jane@email.com"
                      name="email"
                      type="email"
                      className={
                        "form-control" +
                        (errors.email && touched.email ? " is-invalid" : "")
                      }
                      aria-label="Email"
                    />

                    <button
                      type="submit"
                      disabled={loading}
                      className="flex-shrink-0 bg-black hover:bg-gray-800 text-white py-3 px-4"
                    >
                      Subscribe
                    </button>
                  </div>

                  <ErrorMessage name="email">
                    {(msg) => (
                      <div className="flex border-2 border-yellow-500 text-yellow-900 p-3 justify-center text-md mt-1 mb-2 font-semibold">
                        <span className="my-auto mr-1">
                          {" "}
                          <BsInfoCircle />{" "}
                        </span>
                        <span className="my-auto">{msg}</span>
                      </div>
                    )}
                  </ErrorMessage>

                  {message ? (
                    <div className="flex border-2 border-yellow-500 text-yellow-900 p-3 justify-center text-md mt-1 mb-2 font-semibold">
                      {message}
                    </div>
                  ) : null}
                </Form>
              )}
            </Formik>

            <p className="text-sm font-semibold">
              GDPR Compliant. We’ll never share your email or spam you.{" "}
            </p>
          </aside>
        </div>
        <div className="flex flex-row flex-wrap px-6 pt-16 lg:px-0">
          <div className="w-full lg:w-1/4 pb-4">
            <h4 className="font-bold text-lg leading-snug mb-2">Explore</h4>
            <nav className="flex flex-col items-start mb-4">
              <Link to="/coworking-spaces" className="text-link mb-3">
                Coworking Space
              </Link>
              <Link to="/event-spaces" className="text-link mb-3">
                Event Spaces
              </Link>
              <Link to="/book-a-tour" className="text-link mb-3">
                Book a Tour
              </Link>
              <Link to="/blog" className="text-link mb-3">
                Blog
              </Link>
            </nav>
          </div>
          <div className="w-full lg:w-1/4 pb-4">
            <h4 className="font-bold text-lg leading-snug mb-2">Company</h4>
            <nav className="flex flex-col items-start">
              <Link to="/about" className="text-link mb-3">
                About Us
              </Link>
              <Link to="/programs" className="text-link mb-3">
                Programs &amp; Partnerships
              </Link>
              <Link to="/careers" className="text-link mb-3">
                Careers
              </Link>
              <Link to="/gallery" className="text-link mb-3">
                Gallery
              </Link>
              <Link to="/contact-us" className="text-link mb-3">
                Contact Us
              </Link>
              {/* <a
                href="//medium.com/@theventurespark"
                className="text-link mb-3"
              >
                News
              </a> */}
            </nav>
          </div>
          <div className="w-full lg:w-1/4 pb-4">
            <h4 className="font-bold text-lg leading-snug mb-2">Community</h4>
            <nav className="flex flex-col items-start mb-4">
              <Link to="/resources" className="text-link mb-3">
                Resources
              </Link>
              <Link to="/events" className="text-link mb-3">
                Events
              </Link>
            </nav>
          </div>
          <div className="w-full lg:w-1/5 pb-4 lg:text-right lg:ml-auto">
            <h4 className="font-bold flex flex-row text-lg leading-snug mb-2 justify-end">
              <span>Visit Us</span>
              <svg
                className="stroke-current text-yellow-500 inline-block h-6 w-6"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="7" y1="17" x2="17" y2="7" />
                <polyline points="7 7 17 7 17 17" />
              </svg>
            </h4>
            <address className="font-normal not-italic text-xl mb-4">
              <a
                href="https://www.google.ng/maps/place/5+Kwaji+Cl,+Wuse+904101,+Abuja/@9.0907667,7.4728436,17z/data=!3m1!4b1!4m5!3m4!1s0x104e0af3494d0f55:0xbdbee8a336114659!8m2!3d9.0907667!4d7.4750323"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black underline hover:text-gray-500 font-normal"
              >
                5. Kwaji Close, Maitama, Abuja.
              </a>
            </address>
          </div>
          <div className="w-full lg:w-auto mt-auto mr-auto py-4">
            <div className="flex flex-row space-x-4 flex-wrap lg:justify-end">
              <a
                href="//linkedin.com/company/the-ventures-park/"
                className="text-3xl text-gray-50 hover:text-black mb-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiLinkedin />
              </a>
              <a
                href="//instagram.com/theventurespark"
                className="text-3xl text-gray-50 hover:text-black mb-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiInstagram />
              </a>
              <a
                href="//twitter.com/theventurespark"
                className="text-3xl text-gray-50 hover:text-black mb-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiTwitter />
              </a>
              <a
                href="//facebook.com/theventurespark"
                className="text-3xl text-gray-50 hover:text-black mb-2 ml-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiFacebook />
              </a>
            </div>
          </div>
          <div className="w-full lg:w-auto pt-4 lg:pt-0 lg:my-auto">
            <p>
              &copy;{new Date().getFullYear()} All Rights Reserved. Ventures
              Park.
            </p>
          </div>
        </div>
      </footer>
    </React.Fragment>
  )
}
