import React from "react"
import type { PageProps } from "gatsby"
import { Footer, Navbar } from "../components"

const Layout: React.FC<PageProps> = ({ children }) => {
  return (
    <main>
      <Navbar />
      {children}
      <Footer />
    </main>
  )
}

export default Layout
