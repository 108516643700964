// GraphQ

import { ApolloClient, InMemoryCache } from "apollo-boost"
import { ApolloLink } from "apollo-link"
import { setContext } from "apollo-link-context"
//
import { onError } from "apollo-link-error"
import { createUploadLink } from "apollo-upload-client"
import React from "react"
//
import { toast } from "react-toastify"
import { resolvers, typeDefs } from "./resolvers"

// GraphQL cache
const cache = new InMemoryCache()

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) =>
      toast.error(({ closeToast }) => (
        <div className="px-3 d-flex">
          <svg className="text-icon my-auto mr-2 small">
            <use xlinkHref="../../symbol-defs.svg#info"></use>
          </svg>

          <span className="my-auto">{message}</span>
        </div>
      ))
    )
  }

  if (networkError) {
    toast.error(({ closeToast }) => (
      <div className="px-3 d-flex">
        <svg className="text-icon my-auto mr-2 small">
          <use xlinkHref="../../symbol-defs.svg#info"></use>
        </svg>

        <span className="my-auto">Network error</span>
      </div>
    ))
  }
})

const authLink = setContext((_, { headers }) => {
  const token = typeof window !== "undefined" && localStorage.getItem("token")

  if (token) {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    }
  }
})

const uploadLink = createUploadLink({
  uri: "https://vpbackend-dot-ventures-park-web.wl.r.appspot.com/graphql",
  credentials: "same-origin",
})

const link = ApolloLink.from([errorLink, authLink, uploadLink])

//===========================================

// GraphQL Client config
const client = new ApolloClient({
  cache,
  link,
  typeDefs,
  resolvers,
  defaultOptions: {
    mutate: { errorPolicy: "none" },
  },
})

cache.writeData({
  data: {
    isLoggedIn:
      typeof window !== "undefined" && !!localStorage.getItem("token"),
    showSignUp: false,
    showLogin: false,
    showReset: false,
  },
})

export default client

// http://localhost:8080/graphql
// https://vpbackend-dot-ventures-park-web.wl.r.appspot.com/graphql
