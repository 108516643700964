import React from "react"
import { graphql, useStaticQuery } from "gatsby"
/* @ts-ignore */
import { Helmet, HelmetProps } from "react-helmet"

const SEO_IMAGE_URL = require("../images/favicon.png")

interface IMetaTags {
  title: string
  description?: string
  url?: string
  contentType?: string
  published?: string
  updated?: string
  category?: string
  tags?: string
  twitter?: string
  image?: string
  schema?: any
  path?: string
}

const getMetaTags = ({
  title,
  description,
  url,
  contentType,
  published,
  updated,
  category,
  tags,
  twitter,
  image,
}: IMetaTags) => {
  const metaTags = [
    { itemprop: "name", content: title },
    { itemprop: "description", content: description },
    { name: "description", content: description },
    { name: "twitter:site", content: "@theventurespark" },
    { name: "twitter:title", content: `${title} • The Ventures Park` },
    { name: "twitter:description", content: description },
    { name: "twitter:creator", content: twitter || "@theventurespark" },
    { name: "og:title", content: `${title} • The Ventures Park` },
    { name: "og:type", content: contentType },
    { name: "og:url", content: url },
    { name: "og:description", content: description },
    { name: "og:site_name", content: "The Ventures Park" },
    { name: "og:locale", content: "en_EN" },
    // { name: 'fb:app_id', content: '<FB App ID>' },
  ]

  if (published)
    metaTags.push({ name: "article:published_time", content: published })
  if (updated)
    metaTags.push({ name: "article:modified_time", content: updated })
  if (category) metaTags.push({ name: "article:section", content: category })
  if (tags) metaTags.push({ name: "article:tag", content: tags })
  // if (image) {
  metaTags.push({ itemprop: "image", content: SEO_IMAGE_URL })
  metaTags.push({ name: "twitter:image:src", content: SEO_IMAGE_URL })
  metaTags.push({ name: "og:image", content: SEO_IMAGE_URL })
  metaTags.push({ name: "twitter:card", content: "summary_large_image" })
  // } else {
  //   metaTags.push({ name: "twitter:card", content: "summary" });
  // }

  return metaTags
}

// const getHtmlAttributes = {(schema: any}) => {
//   let result = {
//     lang: "en",
//   };
//   if (schema) {
//     result = {
//       ...result,
//       itemscope: undefined,
//       itemtype: `http://schema.org/${schema}`,
//     };
//   }
//   return result;
// };

export const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          image
          siteUrl
        }
      }
    }
  `)
  return data.site.siteMetadata
}

/**
 * Layout/Page Title
 */
const HelmetSEO: React.FC<IMetaTags & HelmetProps> = ({
  children,
  ...props
}) => (
  <Helmet titleTemplate="%s &bull; The Ventures Park" meta={getMetaTags(props)}>
    <title>{props.title}</title>
    {children}
  </Helmet>
)

const SEO = ({
  title,
  description,
  image,
  pathname,
  children,
}: IMetaTags & { pathname?: string; children?: any }) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image: defaultImage,
    siteUrl,
  } = useSiteMetadata()
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || defaultImage,
    url: `${siteUrl}${pathname || ``}`,
  }
  return (
    <>
      <title>{seo.title} &bull; The Ventures Park</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      <meta name="og:title" content={seo.title} />
      <meta name="og:description" content={seo.description} />
      <meta name="og:type" content="website" />
      <meta name="og:url" content={seo.url} />
      <meta name="og:image" content={seo.image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <link
        rel="icon"
        href="data:image/svg+xml, <svg viewBox='0 0 1080 1080' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='1080' height='1080' fill='%23F2D344'/><path d='M176 904V176L540.5 715.5L855 249H716.5L540.5 517L313 176H905V904H176Z' stroke='black' stroke-width='30' stroke-linecap='round' stroke-linejoin='round'/></svg>"
      />
      {children}
    </>
  )
}

export default SEO
