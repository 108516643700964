import React from "react"
import Layout from "./index"
import client from "../server/client"
import { ApolloProvider } from "@apollo/react-hooks"
import { QueryClient, QueryClientProvider } from "react-query"
import { ToastContainer } from "react-toastify"

const queryClient = new QueryClient()

const wrapPageElement = ({ element, props }) => (
  <ApolloProvider client={client}>
    <QueryClientProvider client={queryClient}>
      <ToastContainer
        position="bottom-center"
        autoClose={10000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        closeButton={false}
        draggable
        pauseOnHover
      />
      <Layout {...props}>{element}</Layout>
    </QueryClientProvider>
  </ApolloProvider>
)

export default wrapPageElement
