// @ts-nocheck
import React, { useState, useEffect, createContext } from "react"
import LazyLoad from "vanilla-lazyload"
import Placeholder from "../images/placeholder.svg"

const Context = createContext()
const options = { elements_selector: ".lazyImage__img" }

export const ImageProvider = ({ children }) => {
  const [lazyLoad, setLazyLoad] = useState<boolean>(null)

  useEffect(() => {
    if (!lazyLoad) setLazyLoad(new LazyLoad(options))
    return () => lazyLoad && lazyLoad.destroy()
  }, [lazyLoad])

  return <Context.Provider value={lazyLoad}>{children}</Context.Provider>
}

export const withImage = (Component) => (props) => (
  <Context.Consumer>
    {(context) => <Component {...props} lazyLoad={context} />}
  </Context.Consumer>
)

const LazyImage = ({ src, aspectRatio, className, lazyLoad }) => {
  // const paddingTop = `${(aspectRatio[1] / aspectRatio[0]) * 100}%`;

  useEffect(() => {
    if (lazyLoad) lazyLoad.update()
  }, [src, aspectRatio, lazyLoad])

  return (
    <img
      src={Placeholder}
      className={`lazyImage__img ${className}`}
      data-src={src}
      alt="The Ventures Park"
    />
  )
}

export default withImage(LazyImage)
